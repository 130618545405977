.app-loader-logo {
  position: relative;
  width: 30rem;
  height: 30rem;
  align-self: center;
  justify-self: center;

  @media only screen and (max-width: 768px) {
    width: 20rem;
    height: 20rem;
  }
}
.app-loader-logo * {
  position: relative;
  z-index: 23333;
}

.app-loader-logo img {
  height: 100%;
  animation: 4s ease 0s normal forwards 1 fadein;
  animation-iteration-count: infinite;
}

.app-loader-logo svg {
  height: 100%;
  animation: 4s ease 0s normal forwards 1 fadein;
  animation-iteration-count: infinite;
}

@keyframes fadein {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
