.container {
  display: grid;

  &__service {
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.75 * 0.8), 1fr));

    @media only screen and (max-width: 320px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.75 * 0.8 * 0.75), 1fr));
    }
  }

  &__shop {
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.6), 1fr));

    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.6 * 0.5), 1fr));
    }
  }

  &__category {
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.75), 1fr));

    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(auto-fit, minmax(calc(var(--display-card-height) * 0.75 * 0.5), 1fr));
    }
  }

  gap: 2rem;
  justify-content: center;
  width: 100%;
}

.container > div {
  justify-self: center;
  align-self: center;
}
