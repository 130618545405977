.padding-5 {
  padding: 0.3rem;
}
.padding-10 {
  padding: 0.625rem;
}
.padding-20 {
  padding: 0.8rem;
}
.padding-horizontal-20 {
  padding: 0 0.8rem;
}
.padding-horizontal-30 {
  padding: 0 30px;
}
.padding-horizontal-14 {
  padding: 0 14px;
}
.padding-vertical-20 {
  padding: 20px 0;
}
.padding-vertical-10 {
  padding: 10px 0;
}
.padding-vertical-5px {
  padding: 5px 0;
}
.padding-8 {
  padding: 8px;
}
.padding-horizontal-8 {
  padding: 0 8px;
}
.padding-24 {
  padding: 24px;
}
.padding-14 {
  padding: 14px;
}
.padding-horizontal-10 {
  padding: 0 10px;
}
.padding-horizontal-15 {
  padding: 0 15px;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-bottom-20 {
  padding-bottom: 20px
}
.padding-50px {
  padding: 50px;
}
