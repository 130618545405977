.transform {
  &:hover {
    transform: scale(1.008);
    -webkit-transition: transform 0.3s;
  }
}

.title {
  margin-top: 1rem;
  bottom: 0;
  right: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &__price {
    margin-top: 0.5rem;
    font-size: large;
  }

  &__category {
    margin-bottom: 0 !important;
  }

  &__text {
    font-size: large;

    @media only screen and (max-width: 576px) {
      font-size: 15px;
    }
  }
}

.divider {
  margin: 12px 0 !important;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center center;
  pointer-events: none;
  border-radius: 0.3125rem;
}

.imageContainer {
  height: calc(var(--display-card-height) * 0.8);

  @media only screen and (max-width: 320px) {
    height: calc(var(--display-card-height) * 0.8 * 0.75);
  }
}

.shopImageContainer {
  height: calc(var(--display-card-height) * 0.6);

  @media only screen and (max-width: 576px) {
    height: calc(var(--display-card-height) * 0.6 * 0.5);
  }
}

.categoryImageContainer {
  height: var(--display-card-height);

  @media only screen and (max-width: 576px) {
    height: calc(var(--display-card-height) * 0.5);
  }
}

.container {
  position: relative;
  overflow: hidden;
  -webkit-transition: transform 1s;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  // margin-bottom: 1rem;

  &__serviceAspectRatio {
    width: calc(var(--display-card-height) * 0.8 * 0.75);

    @media only screen and (max-width: 320px) {
      width: calc(var(--display-card-height) * 0.8 * 0.75 * 0.75);
    }
  }

  &__shopAspectRatio {
    width: calc(var(--display-card-height) * 0.6);

    @media only screen and (max-width: 576px) {
      width: calc(var(--display-card-height) * 0.6 * 0.5);
    }
  }

  &__categoryAspectRatio {
    width: calc(var(--display-card-height) * 0.75);

    @media only screen and (max-width: 576px) {
      width: calc(var(--display-card-height) * 0.75 * 0.5);
    }
  }

  &:hover {
    transform: scale(1.05);
    -webkit-transition: transform 0.3s;
  }
}
