.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-50-percent {
  border-radius: 50%;
}
.border-none {
  border: none;
}
