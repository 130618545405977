.text-align-center {
  text-align: center;
}.text-align-justify {
  text-align: justify;
}
.text-decoration-underline {
  text-decoration: underline;
}
.font-weight-600 {
  font-weight: 600;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-12px {
  font-size: 12px;
}


.font-size-17px {
  font-size: 17px;
}

.normal-text {
  font-size: clamp(10px, 2vw, 14px);
}

.uppercase {
  text-transform: uppercase;
}
