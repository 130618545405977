.list {
  width: 100%;
}

.listItem {
  cursor: pointer;
  transition: 0.3s;
}

.listItem:hover {
  opacity: 0.5;
}

.listItem:active {
  opacity: 1;
}
