.position-fixed {
  position: fixed;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.top-zero {
  top: 0;
}
.bottom-zero {
  bottom: 0;
}
.left-zero {
  left: 0;
}
.right-zero {
  right: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.top-50-percent {
  top: 50%;
}
.bottom-50-percent {
  bottom: 50%;
}
.right-35 {
  right: 35px;
}
.left-35 {
  left: 35px;
}
.position-sticky {
  position: sticky;
}
