.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 1rem auto 2rem auto;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 1400px;
}
