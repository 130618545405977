.height-100-percent {
  height: 100%;
}
.max-height-35 {
  max-height: 35px;
}
.height-36 {
  height: 36px;
}
.height-40 {
  height: 40px;
}
.height-50px {
  height: 50px;
}
.min-height-50 {
  min-height: 50px;
}
.min-height-100-percent {
  min-height: 100%;
}
.height-10 {
  height: 10px;
}
.height-200 {
  height: 200px;
}
.max-height-max-content {
  max-height: max-content;
}
.height-max-content {
  height: max-content;
}
