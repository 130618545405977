.flex1 {
  flex: 1 !important;
}

.marginInlineZero {
  margin-inline-end: 0 !important;
}

.width100 {
  width: 100% !important;
}
