:root {
  --primary-app-color: #fb6b90;
  --app-background-color: #fff;
  --app-header-color: #000000;
  --app-icon-color: #424242;
  --input-border-color: #d9d9d9;
  --swiper-navigation-color: #141414 !important;
  --swiper-theme-color: #141414 !important;
  transition: all ease-in 0.5s;
  --display-card-height: 28.25rem;
  --display-card-width: 22rem;
}

[app-theme='dark'] {
  --primary-app-color: #fb6b90;
  --app-background-color: #141414;
  --app-header-color: #ffffff;
  --app-icon-color: #d9d9d9;
  --input-border-color: #424242;
  --swiper-navigation-color: #fff !important;
  --swiper-theme-color: #fff !important;
  transition: all ease-in 0.5s;
}

iframe {
  display: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--app-background-color);
}

h4 {
  color: var(--app-header-color);
}

h1,
h1,
h3,
h4,
h5 {
  margin-top: 0 !important;
}

.ant-select-selection-item {
  display: flex;
}

.ant-list-item-extra {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-radio-wrapper span.ant-radio + * {
  width: 100%;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 26px !important;
  }
  h2 {
    font-size: 21px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 15px !important;
  }
}

.ant-btn-link {
  color: inherit;
}
.ant-btn-link:not(:disabled):hover {
  color: inherit;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 10px;
}

/* Style the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: transparent;
}

.ant-picker-panels {
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

/* Hide the scrollbar when not hovering */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

/* Hide the scrollbar when not hovering */
::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.ant-layout-sider {
  background-color: transparent !important;
}

.ant-upload-list-item-name {
  color: var(--app-icon-color) !important;
}
