.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  &__title {
    flex: 1;
    margin-bottom: 0 !important;
  }

  &__input {
    flex: 1;
    max-width: 30rem;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;

    &__title {
      margin-bottom: 0.5rem !important;
    }
  }
}
